import { acquireAccessToken } from "./Authentication/acquire_token";
import { CONFIG_URL } from "./Settings/envConstants";
let apiUrl = "";

if(process.env.REACT_APP_NODE_ENV && process.env.REACT_APP_NODE_ENV === 'local') {
  apiUrl = CONFIG_URL.apiUrl
} else if(process.env.REACT_APP_NODE_ENV === 'QA') {
  apiUrl = CONFIG_URL.microsoftapiUrl
}
const ChatAPIService = {

  postRequest: async (userPrompt, sessionId, userId, controllerSignal) => {

    try {
      let token = "";
      const requestHeaders = {
        'Content-Type': 'application/json',
      }
      if(process.env.REACT_APP_NODE_ENV === 'QA') {
        token = await acquireAccessToken();
        requestHeaders['Authorization'] = `Bearer ${token}`;
      }
      const requestParams = {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify({
          userId: userId,
          userPrompt: userPrompt,
          sessionId: sessionId,
          stream: true
        }),
        signal: controllerSignal,
      }

      let response = null;

      if(process.env.REACT_APP_NODE_ENV && (process.env.REACT_APP_NODE_ENV === 'local')) {
        response = await fetch(apiUrl + 'chat/chat', requestParams);
      } else if(process.env.REACT_APP_NODE_ENV === 'QA') {
        response = await fetch(apiUrl, requestParams);
      } else {
        response = await fetch('chat/chat', requestParams);
      }

      if (!response.ok) {
        // throw new Error('Request failed');
        throw new Error('An error occurred. Please try again');
      }

      return response;
    } catch (error) {
      console.error('Error:', error);
      if (error.name === 'AbortError') {
        localStorage.setItem('storeChat', false)
        throw "Oops, Request cancelled"
      } else {
        throw error;
      }
    }
  },
};

export default ChatAPIService;
