function Configuration() {
    console.log('deployed through pipeline----2/1');
    let configObject = {}
    if(process.env.REACT_APP_NODE_ENV === 'QA') {
        configObject = {
            clientId: "62c089f3-76d7-496d-903f-85bfd51f7053",
            tenantId: "https://login.microsoftonline.com/f26ef77b-4ea6-4dd3-b7b3-0b4f109e26bd",
            redirectUri: "https://usqavirtualstylistwebapp.azurewebsites.net/",
            // redirectUri: "http://localhost:3000/",
            microsoftapiUrl: "https://us-qa-virtualstylist-apim.azure-api.net/Chat",
            // apiUrl: "http://localhost:3001/",
            // apiUrl: "https://dev.askralph.ralphlauren.com/",
            apiUrl: "https://usqavirtualstylistwebapi.azurewebsites.net/",
            feedBackCheckMonth : process.env.SHOW_FEEDBACK_MODAL_IN_DAYS || 2,
            developers: ["abarajithan.pm@ralphlauren.com", "shubhangichainsing.thakur@ralphlauren.com", "mickey.abbatiello@ralphlauren.com",
            "shivakeerthi.karani@ralphlauren.com", "nicolas.smith@ralphlauren.com", "gaby.prieto@ralphlauren.com"],
        }
    } else if(process.env.REACT_APP_NODE_ENV === 'local') {
        configObject = {
            // apiUrl: "http://localhost:3000/",
            apiUrl: "http://localhost:3001/",
            feedBackCheckMonth: process.env.REACT_APP_SHOW_FEEDBACK_MODAL_IN_DAYS || 2,
        }
    } else {
        configObject = {
            apiUrl: process.env.REACT_APP_API_URL,
            // apiUrl: "https://dev.askralph.ralphlauren.com/",
            // apiUrl: "https://uat.askralph.ralphlauren.com/",
            // apiUrl: "https://askralph.ralphlauren.com/",
            feedBackCheckMonth: process.env.REACT_APP_SHOW_FEEDBACK_MODAL_IN_DAYS || 2,
        }
    }
    return configObject;
}

export const CONFIG_URL = Configuration()