/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { loader } from '../Services/Redux/Reducers/commonSlice';
import { apiError, conversationList, isStream, isStopButtonTriggered, lastConversation } from '../Services/Redux/Reducers/chatSlice';
import { addchat, uniqueSessionHistory, getAppFeedBack, updateShopClicked } from "../Services/ApiServices/chatServices";
import ChatAPIService from "../Services/ChatAPIService";
import { resetSummedresponse, updateSummedResponse } from "../Services/CommonServices/helper";
import MessageHistory from "../CommonComponents/MessageHistory";
import { css } from '@emotion/react'
import cloneDeep from 'lodash/cloneDeep'
import LikeAndDislike from "../CommonComponents/LikeAndDislike";
import { Cookies } from 'react-cookie-consent';
import { useAbortController } from "../Context/AbortControllerContext";
import { appLevelFeedback, likeApiError, showLikeDisLikeState } from "../Services/Redux/Reducers/feedBackSlice";
import { userPrompt } from "../Services/Redux/Reducers/userSlice";
import { getRandomImage, getDesktopRandomImage } from "../Services/CommonServices/helper";

const ROOT_CSS = css({
  height: 600,
  width: 400
});

function Chat() {
  const { createController, abortController } = useAbortController();
  // const savedlocalStorageData = JSON.parse(localStorage.getItem('persist_session'))
  const input = useSelector((state) => state.user.userPrompt)
  const [error, setError] = useState("");
  const name = Cookies?.get('ar_user_email') ? Cookies.get('ar_user_email') : "";
  const shortName = name ? name.charAt(0).toUpperCase() : "";
  const customerId = Cookies?.get('ar_user_Id') ? Cookies.get('ar_user_Id') : "";
  const inputRef = useRef(null);
  const [isHeight, setHeight] = useState(false)
  const [isPlaceHolderVisible, setIsPlaceHolderVisible] = useState(true);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);
  const [sessionId, setSessionId] = useState(sessionStorage.getItem('persist_session') ? sessionStorage.getItem('persist_session') : null);
  const loading = useSelector((state) => state.common.isLoader)
  const responseStop = useSelector((state) => state.common.isResponseStopped)
  const dispatch = useDispatch();
  const streamedResponse = useRef(resetSummedresponse());
  const [isResponseComplete, setIsResponseComplete] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const conList = useSelector((state) => state.chat.conversationList);
  const isApiError = useSelector((state) => state.chat.apiError);
  const [isScreenResponsive, setIsScreenResponsive] = useState(false);
  const effectRan = useRef(false);
  const [sessionChangeStatus, setSessionChangeStatus] = useState();
  const [lastConvoId, setLastConvoId] = useState("");
  const [apiLoading, setApiLoading] = useState(false);
  const isStopTriggered = useSelector((state) => state.chat.isStopButtonTriggered)
  const [downArrow, setShowDownArrow] = useState(false);
  const [dynamicHeight, setDynamicHeight] = useState(0);

  // used for unsubscribe the modal
  const handleFocus = () => {
    setIsPlaceHolderVisible(false);
  }

  const onHandleInputChange = (e) => {
    const inputValue = e.target.value
    dispatch(userPrompt(inputValue));
    setIsSendButtonDisabled(inputValue.trim() === '');
    if (inputValue.length > 136 || inputValue.length) {
      setHeight(true)
    }
    else {
      setHeight(false)
    }
  }

  const handleClickOutside = event => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsPlaceHolderVisible(input === '' || !input);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [input]);

  useEffect(() => {
    if (!effectRan.current) {
      console.log("effect applied - only on the FIRST mount");
      if (input) {
        streaming(input);
      }
    }
    if (sessionId != null) {
      storeOldMessage();
      app_feedback()
    }
    return () => effectRan.current = true;
  }, []);

  // for input expansion 
  useEffect(() => {
    inputRef.current.style.height = "0px";
    const scrollHeight = inputRef.current.scrollHeight;
    //condition to set input height

    if (isHeight === true) {
      inputRef.current.style.height = (scrollHeight) + "px";
    } else {
      inputRef.current.style.height = (scrollHeight - 10) + "px";
    }
  }, [input]);

  //iphone height fix 
  useEffect(() => {

    let userAgent = window.navigator.userAgent.toLowerCase(),
      macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
      windowsPlatforms = /(win32|win64|windows|wince)/i,
      safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if ((macosPlatforms.test(userAgent)) || (windowsPlatforms.test(userAgent)) || ((/android/.test(userAgent)) && !safari)) {
      setIsScreenResponsive(true);
    }
  }, []);

  const storeOldMessage = () => {
    if (process.env.REACT_APP_APPLICATION_VERSION === "Microsoft") {
      return
    }
    // const conIndex = convoList.findIndex((element) => element.uid === lastItem.uid)
    const params = [customerId, sessionId];
    try {
      // const params = ['bhavana.tomar@ralphlauren.com','b116faa8-1ac2-4bbd-9062-05514e9ca964']
      uniqueSessionHistory(
        params,
        (response) => {
          // Chat History storing the messages
          // setData(response.data.data);
          dispatch(conversationList(response.data.data));
          dispatch(isStream(false))
        },
        (err) => {
          dispatch(apiError(err.message));
          dispatch(loader(false));
        }
      );
    }
    catch (err) {
      dispatch(apiError("An error occurred. Please try again"));
    }
  };

  //For Feedback check
  const app_feedback = () => {
    if (process.env.REACT_APP_APPLICATION_VERSION === "Microsoft") {
      return
    }
    const params = [customerId && customerId];
    // const params = ['shubhangichainsing.thakur@ralphlauren.com'];
    // const params = [userId, JSON.stringify(sessionId)];
    // const params = ['bhavana.tomar@ralphlauren.com','b116faa8-1ac2-4bbd-9062-05514e9ca964']
    try {
      getAppFeedBack(
        params,
        (response) => {
          // appLevel data 
          dispatch(appLevelFeedback(response.data))
        },
        (err) => {
          dispatch(apiError("An error occurred. Please try again"));
          dispatch(loader(false));
        }
      );
    }
    catch (err) {
      dispatch(apiError("An error occurred. Please try again"));
    }
  };

  const handleLoader = () => {
    dispatch(loader(true))
    setIsSendButtonDisabled(true);
    setIsPlaceHolderVisible(true);
  };

  const handleStopResponse = () => {
    abortController();
    setApiLoading(false);
    setIsPlaceHolderVisible(true);
    dispatch(loader(false));
    dispatch(showLikeDisLikeState(false))
    dispatch(isStopButtonTriggered(true))
    localStorage.setItem("stoppedResponseId", lastConvoId);
    dispatch(lastConversation(lastConvoId))
  };

  const callUpdateShopClicked = async (params) => {
    if (process.env.REACT_APP_APPLICATION_VERSION === "Microsoft") {
      return
    }
    try {
      await updateShopClicked(params, (response) => { // updating the shopClicked value    
        if (response.status === 200) {
          let conHis = cloneDeep(conList)
          const conIndex = conHis.findIndex((element) => element.uid === response.data.data.uid)
          conHis[conIndex] = response.data.data
          dispatch(conversationList(conHis));
        }
      }, (err) => {
      });
    } catch (err) {
      console.log('getting error in updating the shopClicked Value update', err);
    }
  }

  //update Shop Card Flag
  const updateShopCard = async (convoId, id, product) => {
    const params = {
      convoId,
      id
    }
    await callUpdateShopClicked(params);
    console.log('product.url', product.url);
    window.open(product.url, "_blank", "noopener,noreferrer");
  }

  const handleValueChange = (childValue) => {
    let test = false
    if (childValue.shopClicked) {
      return
    }
    let conHis = cloneDeep(conList)
    const matchObject = conHis.find((element) => element.uid === childValue.convoId)
    matchObject && matchObject?.response?.products?.map((items) => {
      if (items.id === childValue.id) {
        if (items.shopClicked) {
          test = true
        }
      }
    })

    if (childValue.type === "quickshop" && !test) {
      const { convoId, id, product } = childValue
      updateShopCard(convoId, id, product);
      storeOldMessage()
    }

    dispatch(isStream(true))

    console.log("childValue : ", childValue)
    if (childValue !== true) {

      streamedResponse.current = resetSummedresponse();
      streaming(childValue.text, childValue.query);


      //  if(abortController ){
      //     setChildVal(childValue.text)
      //       abortController.abort();
      //   }
    } else {
      handleStopResponse();
    }
  };

  useEffect(() => {
    // for scroll struck
    if (window.innerWidth > 576) {
      const timeout = setTimeout(() => {
        let button = document.querySelector(".follow_btn")
        if (button) {
          button.click();
        }
      }, 20);
      return () => clearTimeout(timeout);
    }
  }, [loading])

  const storeMessage = (conHis) => {
    if (process.env.REACT_APP_APPLICATION_VERSION === "Microsoft") {
      return
    }
    if (localStorage.getItem('storeChat') !== "false") {
      let convo = cloneDeep(conHis)
      let convoList = cloneDeep(conHis)
      let lastItem = convo.pop();
      const conIndex = convoList.findIndex((element) => element.uid === lastItem.uid)
      try {
        addchat(lastItem, (response) => { // Chat History storing the messages     
          convoList[conIndex]['showLikeDislike'] = false
          convoList[conIndex]['error'] = "Oops, Something went wrong"
          dispatch(conversationList(convoList));
        }, (err) => {
          // convoList[conIndex]['error'] = err.message
          // dispatch(conversationList(convoList));
          console.log("An error occurred in add chat api call  in chat.", err.message);
          dispatch(loader(false));
        });
      }
      catch (err) {
        console.log("An error occurred in add chat api call  in chat.", err.message);
      }
    } else {
      localStorage.setItem('storeChat', true)
    }
  }
  const streaming = async (input, query = "") => {
    // setIsTriggered(false);
    localStorage.setItem("stoppedResponseId", null);
    dispatch(lastConversation(""));
    // dispatch(isResponseStopped(false));
    dispatch(isStopButtonTriggered(false))
    const newController = createController();
    dispatch(likeApiError(true));
    dispatch(apiError(false));
    let sessionChange = false
    setSessionChangeStatus();
    let button = document.querySelector(".follow_btn")
    if (button) {
      button.click();
    }
    if (loading) return;
    if (input === undefined || input === '' || input === null) {
      return;
    }
    setApiLoading(true);
    handleLoader();
    streamedResponse.current = resetSummedresponse();
    setIsStreaming(true);
    let lastGeneratedId = crypto.randomUUID().slice(2)
    setLastConvoId(lastGeneratedId);
    let userParam = {
      uid: lastGeneratedId,
      backgroundImage: getRandomImage(),
      desktopbackgroundImage: getDesktopRandomImage(),
      requestDateTime: new Date().toString(),
      userId: customerId,
      messageId: "",
      question: input,
      response: {},
      sessionId: sessionId,
      sessionChange: sessionChange,
      showLikeDislike: false,
      error: "",
      likeStatus: "",
      feedBack: "",
      application_sessionId: ""
    }
    if (process.env.REACT_APP_NODE_ENV && process.env.REACT_APP_NODE_ENV === 'local') {
      userParam.application_sessionId = Cookies?.get('ar_user')
    }
    let conHistory = cloneDeep(conList)
    conHistory.push(userParam)
    dispatch(userPrompt(''));
    dispatch(showLikeDisLikeState(true))
    setError("");
    dispatch(conversationList(conHistory));
    setIsResponseComplete(true);
    try {
      const response = await ChatAPIService.postRequest(query !== "" ? query : input, sessionId, name, newController.signal)
      setApiLoading(false);
      var loopRunner = true;
      if (!response?.ok || !response.body) {
        let conHis = cloneDeep(conHistory)
        const conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId)
        conHis[conIndex]['response'] = {
          responseType: "Error",
          errormessage: "An error occurred. Please try again",
          error: "An error occurred. Please try again"
        }
        dispatch(conversationList(conHis));
        // dispatch(isResponseStopped(true));
        storeMessage(conHis)
        loopRunner = false;
        dispatch(loader(false));
        return;
      }

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();

      var lastChunk = '';
      try {
        while (loopRunner && reader) {
          const { value, done } = await reader.read();
          if (done) {
            break;
          }
          const decodedChunk =
            lastChunk + decoder.decode(value, { stream: true });
          const responseArray = decodedChunk.split('\n\n');
          lastChunk = responseArray.pop() ?? '';
          updateResponseSteam(response, responseArray, conHistory, lastGeneratedId, sessionChange);
        }
      } catch (ex) {
        let conHis = cloneDeep(conHistory)
        const conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId)
        if (ex.toString() === "AbortError: BodyStreamBuffer was aborted") {
          // dispatch(isResponseStopped(true));
          conHis[conIndex]['response'] = {
            responseType: "Error",
            errormessage: "Oops, Request cancelled",
            error: "Oops, Request cancelled"
          }
          dispatch(conversationList(conHis));
        } else {
          conHis[conIndex]['response'] = {
            responseType: "Error",
            errormessage: "An error occurred. Please try again",
            error: "An error occurred. Please try again"
          }
          dispatch(conversationList(conHis));
          // dispatch(isResponseStopped(true));
          storeMessage(conHis)
        }
        dispatch(loader(false))
        return;
      }
    }
    catch (ex) {
      let conHis = cloneDeep(conHistory)
      const conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId)
      conHis[conIndex]['response'] = {
        responseType: "Error",
        errormessage: "An error occurred. Please try again",
        error: `${ex}`
      }
      dispatch(conversationList(conHis));
      // dispatch(isResponseStopped(true));
      storeMessage(conHis)
      dispatch(loader(false));
      return;
    }
    setIsResponseComplete(false);
  }

  const updateResponseSteam = (response, responseArray, conHistory, lastGeneratedId, sessionChange) => {
    responseArray.forEach((responsestring) => {
      const stoppedResponseId = localStorage.getItem("stoppedResponseId");
      if (stoppedResponseId && lastGeneratedId === stoppedResponseId) {
        return;
      }
      responsestring = responsestring.replace('data:', '').trim();
      if (
        responsestring === '' ||
        responsestring === undefined ||
        responsestring === null
      ) {
        return;
      }
      if (responsestring === 'DONE') {
        return;
      }
      if (responsestring === 'error') {    // NEW BLOCK TO HANDLE ERROR CHUNK
        let conHis = cloneDeep(conHistory)
        const conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId)
        conHis[conIndex]['response'] = {
          responseType: "Error",
          errormessage: "An error occurred. Please try again",
          error: "An error occurred. Please try again"
        }
        dispatch(conversationList(conHis));
        // dispatch(isResponseStopped(true));
        storeMessage(conHis)
        dispatch(loader(false))
        return;
      }
      try {
        const apiResponseObject =
          JSON.parse(responsestring);

        const responseObject = JSON.parse(
          apiResponseObject.response,
        );
        if (apiResponseObject.sessionId) {
          if (sessionId == null || sessionId !== apiResponseObject.sessionId) {
            setSessionId(apiResponseObject.sessionId);
            sessionStorage.setItem('persist_session', apiResponseObject.sessionId)
            sessionChange = true
            setSessionChangeStatus(true)
          }
        }
        streamedResponse.current = updateSummedResponse(
          streamedResponse.current,
          { ...responseObject, messageId: apiResponseObject.activityId },
        );
        let conHis = cloneDeep(conHistory)
        let conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId);
        conHis[conIndex]['messageId'] = streamedResponse.current.messageId
        conHis[conIndex]['response'] = streamedResponse.current
        conHis[conIndex]['sessionId'] = apiResponseObject.sessionId
        if (process.env.REACT_APP_NODE_ENV && process.env.REACT_APP_NODE_ENV === 'QA') {
          conHis[conIndex]['application_sessionId'] = apiResponseObject.sessionId;
        }
        conHis[conIndex]['sessionChange'] = sessionChange
        dispatch(conversationList(conHis));
        // Microsoft needs this please do not comment
        console.log('SessionID-', apiResponseObject.sessionId)
      }
      catch (ex) {
        let conHis = cloneDeep(conHistory)
        const conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId)
        conHis[conIndex]['response'] = {
          responseType: "Error",
          errormessage: "An error occurred. Please try again",
          error: "An error occurred. Please try again"
        }
        dispatch(conversationList(conHis));
        // dispatch(isResponseStopped(true));
        storeMessage(conHis)
        dispatch(loader(false))
        return;
      }
    });
  };

  const globalHeader = document.querySelector('.header') || document.querySelector('header');


  // scroll to bottom arrow functionality
  useEffect(() => {
    const pageContainer = document.getElementById('pageContainer');
    const handleScroll = () => {
      const isScrollable = pageContainer.scrollHeight > pageContainer.clientHeight;
      const isAtBottom = pageContainer.scrollTop + pageContainer.clientHeight >= pageContainer.scrollHeight - 10;
      const isTextOnlyWithPrompts = streamedResponse.current.responseType === "TextOnly" && 
                                   streamedResponse.current.suggestedPrompts && 
                                   streamedResponse.current.suggestedPrompts.length > 0;
      
      // Don't show arrow if it's TextOnly with prompts or during loading
      setShowDownArrow(
        isScrollable && 
        !isAtBottom && 
        !isTextOnlyWithPrompts &&
        !(streamedResponse.current.responseType === "" && apiLoading && isPlaceHolderVisible)
      );
    };
    
    const checkIfScrollable = () => {
      const isScrollable = pageContainer.scrollHeight > pageContainer.clientHeight;
      const isTextOnlyWithPrompts = streamedResponse.current.responseType === "TextOnly" && 
                                   streamedResponse.current.suggestedPrompts && 
                                   streamedResponse.current.suggestedPrompts.length > 0;
      
      setShowDownArrow(
        isScrollable && 
        !isTextOnlyWithPrompts && 
        !(streamedResponse.current.responseType === "" && apiLoading && isPlaceHolderVisible)
      );
    };

    const observer = new MutationObserver(() => {
      handleScroll();
      checkIfScrollable();
    });
    observer.observe(pageContainer, { childList: true, subtree: true });
    pageContainer.addEventListener('scroll', handleScroll);
    handleScroll()
    checkIfScrollable(); // Initial check
    return () => {
      pageContainer.removeEventListener('scroll', handleScroll);
      observer.disconnect();

    }
  }, [conList, loading, apiLoading, isResponseComplete, streamedResponse.current]);

  const getScrollOffset = () => {
    const globalHeader = document.querySelector('.header') || document.querySelector('header');
    if (!globalHeader) return 0;
    
    return Math.floor(globalHeader.getBoundingClientRect().height * 1.45);
  };

  const handleSubmissionScroll = async () => {
    const pageContainer = document.getElementById('pageContainer');
    const badgeElements = document.querySelectorAll('#badge-text');
    const lastBadge = badgeElements[badgeElements.length - 1];

    if (pageContainer && lastBadge) {
      await new Promise(resolve => setTimeout(resolve, 100));
      const badgePosition = lastBadge.offsetTop;
      const scrollOffset = getScrollOffset();

      pageContainer.scrollTo({
        top: badgePosition - scrollOffset,
        behavior: 'smooth'
      });
    }
  };


  useEffect(() => {
    if (loading) {
      handleSubmissionScroll();
    }

  }, [loading])


  const renderDynamicEmptyDiv = useCallback(() => {
    const calculateHeight = () => {
      const chatWindow = document.querySelector('.chatwindow');
      const viewportHeight = window.innerHeight;
        
        // Get elements
        const header = document.querySelector('.header') || document.querySelector('header');
        const footer = document.querySelector('.chat-input') || document.querySelector('chat-input');
        const footerGradient = document.querySelector('.footer-gradient') || document.querySelector('footer-gradient');
        const likeDislikeModule = document.querySelector('.likeDislikeModule') ||   document.querySelector('likeDislikeModule');
        const promptsModule = document.querySelector('.prompts') ||   document.querySelector('prompts');
        // const likeDislikeModule = document.getElementsByClassName('likeDislikeModule');
      
        // Get element heights including margins
        const getFullHeight = (element) => {
          if (!element) return 0;
          const styles = window.getComputedStyle(element);
          return element.getBoundingClientRect().height + 
                 parseInt(styles.marginTop) + 
                 parseInt(styles.marginBottom);
        };
      
        const headerHeight = getFullHeight(header);
        const footerHeight = footerGradient?.getBoundingClientRect().height +  getFullHeight(footer) 
        // const footerHeight = 80 + footerGradient
        // const likeDislikeHeight = (conList.length > 1 ? 72 : 56);
        const likeDislikeHeight = likeDislikeModule?.getBoundingClientRect().height | 0
        const promptsHeight = promptsModule?.getBoundingClientRect().height | 0
      
        // Calculate total height including spacing
        const getSpacingByHeight = () => {
          const height = window.innerHeight;
          if (height <= 300) return 40;
          if (height <= 400) return 50;
          if (height <= 500) return 60;
          if (height <= 600) return 70;  // base case
          if (height <= 700) return 80;
          if (height <= 800) return 90;
          if (height <= 900) return 100;
          if (height <= 1000) return 110;
          if (height <= 1100) return 120;
          if (height <= 1200) return 130;
          if (height <= 1300) return 140;
          if (height <= 1400) return 150;
          if (height <= 1500) return 160;
          return 170; // for heights > 1500
        };
      
        const totalSpacingOffset = 100;
        const availableHeight = viewportHeight - (
          headerHeight + 
          footerHeight + 
          likeDislikeHeight + 
          promptsHeight +
          // (conList.length > 1 ? 0 : (globalHeader.getBoundingClientRect().height * 1.25) ) +
          totalSpacingOffset
        );
      
        

      // If chatWindow isn't overflowing yet, return 0
      // if (chatWindow && chatWindow.scrollHeight <= chatWindow.clientHeight) {
      //   return 0;
      // }

      const chatInstances = document.getElementsByClassName("chatInstance");
      const lastChatInstance = chatInstances[chatInstances.length - 1];

      
      if (!lastChatInstance) return 0;

      const chatInstanceHeight = lastChatInstance.getBoundingClientRect().height;
   
      
      // If last chat instance is taller than viewport, no need for empty space
      if (chatInstanceHeight >= availableHeight) {
        return 0;
      }

      // Calculate remaining space needed to push chat instance to top
      const remainingHeight = availableHeight - chatInstanceHeight
      
      console.log('Viewport:', viewportHeight, 'Last Chat Height:', chatInstanceHeight, 'Empty Space:', remainingHeight, 'Header Height', headerHeight, 'Footer Height', footerHeight, 'likeDislikeModuleHeight', likeDislikeHeight, 'promptsHeight', promptsHeight);
      
      return Math.max(remainingHeight, 0);
    };

    return calculateHeight();
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(() => {
        setDynamicHeight(renderDynamicEmptyDiv());
      });
    });

    const chatInstances = document.getElementsByClassName("chatInstance");
    const lastChatInstance = chatInstances[chatInstances.length - 1];
    const chatWindow = document.querySelector('.chatwindow');
    const prompts = document.querySelector('.prompts');
    const likeDislikeModule = document.getElementsByClassName("likeDislikeModule");

    const header = document.querySelector('.header') || document.querySelector('header');
    const footer = document.querySelector('.chat-input') || document.querySelector('chat-input');
    const footerGradient = document.querySelector('.footer-gradient') || document.querySelector('footer-gradient');
    // likeDislikeModule
    
    if (lastChatInstance) {
      resizeObserver.observe(lastChatInstance);
    }
    if (chatWindow) {
      resizeObserver.observe(chatWindow);
    }

    if (likeDislikeModule[0]) {
      resizeObserver.observe(likeDislikeModule[0]);
    }

    if (prompts) {
      resizeObserver.observe(prompts);
    }

    if (header) {
      resizeObserver.observe(header);
    }

    if (footer) {
      resizeObserver.observe(footer);
    }

    if (footerGradient) {
      resizeObserver.observe(footerGradient);
    }

    // Initial calculation
    setDynamicHeight(renderDynamicEmptyDiv());

    // Handle window resize
    window.addEventListener('resize', () => {
      requestAnimationFrame(() => {
        setDynamicHeight(renderDynamicEmptyDiv());
      });
    });

    return () => {
      resizeObserver.disconnect();
    };
  }, [conList, renderDynamicEmptyDiv]);

  // Modify the scrollToBottom function to hide the down arrow button when the user scrolls to the bottom:
  const scrollToEnd = () => {
    const pageContainer = document.getElementById('pageContainer');
    pageContainer.scrollTo({
      top: pageContainer.scrollHeight - 100,
      behavior: 'smooth'
    });
    setShowDownArrow(false);
  };



  // Add useEffect for scroll control
  useEffect(() => {
    const preventScroll = (e) => {
      if (!streamedResponse?.current?.responseType && apiLoading) {
        console.log('Preventing scroll');
        e.preventDefault();
      }
    };

    const pageContainer = document.getElementById('pageContainer');
    if (pageContainer) {
      console.log('Setting overflow to hidden');
      if (!streamedResponse?.current?.responseType && apiLoading) {
        pageContainer.style.overflow = 'hidden';
      }
      else {
        pageContainer.style.overflow = 'auto';
      }
    }

    document.addEventListener('wheel', preventScroll, { passive: false });
    document.addEventListener('touchmove', preventScroll, { passive: false });

    return () => {
      const pageContainer = document.getElementById('pageContainer');

      // execute below code after 2 seconds

      if (pageContainer) {
        pageContainer.style.overflow = 'auto';
      }
      document.removeEventListener('wheel', preventScroll);
      document.removeEventListener('touchmove', preventScroll);



    };
  }, [streamedResponse?.current?.responseType, apiLoading]);



  return (
    <div>
      <div className="d-flex flex-column">
        <div className={`homepage ${loading ? "homepageresize" : ""}`}>
          <div className="page-container" id="pageContainer">
            <div className="chatwindow">

              <div className={`conversationlist chat_options`}>
                <div className={`container container-fluid px-0 px-md-3`}>
                  <div style={{ height: globalHeader.getBoundingClientRect().height * 1.2 }} />
                  {
                    conList.length > 0 &&
                    <div>
                      <MessageHistory shortName={shortName} isAnswerComplete={!isResponseComplete} onValueChange={handleValueChange} effects={isStreaming} error=""
                      />
                      
                      {process.env.REACT_APP_APPLICATION_VERSION !== "Microsoft" && (!loading && isApiError === false && !isStopTriggered) ?
                        <div className="likeDislikeModule">
                          <LikeAndDislike responseId={lastConvoId} sessionChange={sessionChangeStatus} />
                        </div>
                        :
                        <div className="text-danger mt-2 mb-3 likeDislikeModule">
                          {isApiError}
                        </div> 
                      }
                    </div>
                  }
                  <div>
                    {streamedResponse.current.responseType === "" && apiLoading && isPlaceHolderVisible && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="loading-dots">
                          <img
                            src="/static/assets/flowerblink1.svg"
                            alt="dot"
                          />
                          <img
                            src="/static/assets/flowerblink2.svg"
                            alt="dot"
                          />
                          <img
                            src="/static/assets/flowerblink3.svg"
                            alt="dot"
                          />
                        </div>
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div className="Commentdots" style={{ margin: 0 }}>
                            Let me think about that for a second{" "}
                          </div>
                          <div className="loading-dots dots">
                            <span>.</span>
                            <span>.</span>
                            <span>.</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                </div>

                {/* {
                        streamedResponse?.current?.responseType === "TextOnly"  &&     conList.length < 2 &&  <div style={{ height: `${dynamicHeight  - window.innerHeight * 0.27}px` }} />
                } */}

{/* {
  streamedResponse?.current?.responseType === "TextOnly" && conList.length < 2 && (
    <div style={{ 
      height: `${Math.max(
        dynamicHeight - (
          window.innerHeight <= 600 ? window.innerHeight * 0.35 :
          window.innerHeight <= 900 ? window.innerHeight * 0.32 :
          window.innerHeight <= 1200 ? window.innerHeight * 0.3 :
          window.innerHeight * 0.27
        ), 
        0
      )}px` 
    }} />
  )
} */}


{/* {
  streamedResponse?.current?.responseType === "TextOnly" && (
    <div style={{ 
      height: `${Math.max(
        dynamicHeight - (
          window.innerHeight <= 480 ? window.innerHeight * 0.4 :  // Mobile
          window.innerHeight <= 768 ? window.innerHeight * 0.35 : // Tablet
          window.innerHeight <= 1024 ? window.innerHeight * 0.3 : // Small Desktop
          window.innerHeight <= 1440 ? window.innerHeight * 0.27 : // Large Desktop
          window.innerHeight * 0.25 // Extra Large Screens
        ),
        100 // Minimum height
      )}px` 
    }} />
  )
} */}

{/* {
                            conList.length > 1 ?  <div style={{ height: `${dynamicHeight  * 0.7}px` }} /> : <div style={{ height: `${dynamicHeight}px` }} />
                } */}

{/* <div style={{ height: `${dynamicHeight * (conList.length > 1 ? 1.3 : 1)}px` }} /> */}
<div style={{ height: `${dynamicHeight * 1.1}px` }} />

                <div className="d-block d-md-flex container-md justify-content-end prompts px-0 help-links ps-lg-3" >
                  {error === '' && !loading ? (
                    <div id="s-propts">
                      {error === "" && (
                        <div className="footer-tags d-flex promtsFade">
                          {
                            streamedResponse.current.suggestedPrompts &&
                            streamedResponse.current.suggestedPrompts.map((suggested, i) => (
                              <button
                                name="suggestedPrompts"
                                className={`footer_btn ${i === 0 && 'ms-20'} ${i === 2 && 'last-footer-btn me-20'}`}
                                key={i}
                                onClick={() => {
                                  streaming(suggested)
                                }}
                                disabled={loading}
                              >
                                {suggested}
                              </button>
                            ))
                          }
                        </div>
                      )}
                    </div>
                  ) : <div style={{ height: `${50}px` }} />}
                </div>

                {/* {
                            conList.length > 1 &&  <div style={{ height: `${dynamicHeight}px` }} />
                } */}

                {
                  loading && 
<div style={{ height: `${50}px` }} />

                }

{/* <div style={{ height: `${1500}px` }} /> */}


              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-gradient"></div>
      <div className="footer">
        <div className="container container-fluid px-3 textarea-container">
          <div className="col-12 d-flex position-relative">
            <textarea
              id="textarea_id"
              enterKeyHint="send"
              className="form-control form-focus chat-input"
              ref={inputRef}
              value={input}
              type="text"
              placeholder={isPlaceHolderVisible ? "Send a message" : ""}
              onFocus={handleFocus}
              onChange={(e) => onHandleInputChange(e)}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  streaming(input);
                  setHeight(false);
                  dispatch(isStream(true))
                  setIsSendButtonDisabled(true);
                }
              }}
            />
            {loading ? (
              <button
                name="submitMessage"
                className={`submit-arrow ${loading || isSendButtonDisabled ? "disabled" : "enabled"}`}
                onClick={() => handleStopResponse(true)} >
                <img
                  src={"/static/assets/stopbtn.svg"}
                  height="30px"
                  width="30px"
                  alt="stop"
                />
              </button>) : (
              <button
                name="submitMessage"
                className={`submit-arrow
                ${loading || isSendButtonDisabled ? "disabled" : "enabled"}`}
                onClick={() => {
                  dispatch(isStream(true))
                  streaming(input);
                  setHeight(false);
                  setIsSendButtonDisabled(true);
                }}
                disabled={isSendButtonDisabled}
              >
                <img
                  src={
                    isSendButtonDisabled
                      ? "/static/assets/submit_disabled.svg"
                      : "/static/assets/submit_enabled.svg"
                  }
                  height="30px"
                  width="30px"
                  alt="arrow"
                />
              </button>)}

          </div>
        </div>
      </div>
      {downArrow && (
        <button
          className={`down-arrow ${loading ? "arrDisable" : "arrEnable"}`}
          disabled={loading}
          onClick={scrollToEnd}
        >
          <img
            alt="down-arrow"
            src="./static/assets/scrollDown.svg"
          />
        </button>
      )}
    </div>
  );
}

export default Chat;