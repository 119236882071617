/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import {
  getTitle,
} from "../../Services/CommonServices/helper";
import Typewriter from "../../CommonComponents/TypeWriting";
import Divider from "../../CommonComponents/Divider";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import { loader } from '../../Services/Redux/Reducers/commonSlice';
import { storeChat } from '../../Services/Redux/Reducers/chatSlice';
import { SpecificProductDetailsProps } from "../../Services/CommonServices/propTypes";

function SpecificProductDetails(props) {
  const [video, setVideo] = useState([]);
  const [image, setImage] = useState([]);
  const [primaryImage, setPrimaryImage] = useState([]);
  const [showTruncate, setShowTruncate] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [showPlayIcon, setShowPlayIcon] = useState(false);
  const [isOverviewTextLoading, setIsOverviewTextLoading] = useState(false);
  const [isTitleLoading, setIsTitleLoading] = useState(false);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [isConclusionLoading, setIsConclusionLoading] = useState(false);
  const dispatch = useDispatch();
  const conList = useSelector((state) => state.chat.conversationList);
  const loading = useSelector((state) => state.common.isLoader);
  const streamingState = useSelector((state) => state.chat.isStream);
  const [showConclusionLoader, setShowConclusionLoader] = useState(false);
  const location = useLocation()
  const isStopTriggered = useSelector((state) => state.chat.isStopButtonTriggered)

  useEffect(() => {
    if (props.index === conList.length-1 && !isStopTriggered && props.isAnswerComplete && loading) {
      if (
        props.text != null &&
        isOverviewTextLoading === true &&
        getTitle(props.product) !== null &&
        isTitleLoading === true &&
        (props.product === undefined ||
          props.product.length === 0 ||
          (props.product && isProductLoading === true)) &&
        (props.conclusion === undefined ||
          props.conclusion === "" ||
          (props.conclusion &&
            props.conclusion !== "" &&
            isConclusionLoading === true))
      ) {
        dispatch(storeChat(true));
        dispatch(loader(false));
      }
    }
    if (
      !isStopTriggered &&
      !isOverviewTextLoading &&
      !isTitleLoading &&
      props.product.length > 0 &&
      !isProductLoading &&
      !isConclusionLoading &&
      streamingState
    ) {
      dispatch(loader(true));
    }
  }, [
    props.conclusion,
    props.isAnswerComplete,
    props.product,
    props.text,
    isOverviewTextLoading,
    isTitleLoading,
    isProductLoading,
    isConclusionLoading,
    loading,
  ]);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  setTimeout(() => setShowPlayIcon(true), 2000);

  useEffect(() => {
    if (
      props.product &&
      props.product !== null &&
      props.product.length > 0 &&
      props.product[0].media &&
      props.product[0].media !== null
    ) {
      let selectedVideo = props.product[0].media.filter(
        (item) => item.type === "Video" && item.url.match(/rlvideo$/)
      );
      setVideo(selectedVideo && selectedVideo[0]);
      let selectedPrimaryImage = props.product[0].media.filter((item) =>
        item.url.match(/alternate10\?fmt=webp-alpha$/)
      );
      if (selectedPrimaryImage && selectedPrimaryImage.length === 0) {
        selectedPrimaryImage = props.product[0].media.filter(
          (item) =>
            item.url.match(/lifestyle\?fmt=webp-alpha$/) &&
            item.type === "PrimaryImage"
        );
      }
      setPrimaryImage(selectedPrimaryImage && selectedPrimaryImage[0]);
      let selectedImage = props.product[0].media.filter(
        (item) =>
          item.type === "Image" ||
          item.url.match(/alternate3\?fmt=webp-alpha$/) ||
          item.url.match(/alternate5\?fmt=webp-alpha$/) ||
          item.url.match(/alternate4\?fmt=webp-alpha$/)
      );

      //Code for pdp image issue
      if (selectedImage && selectedImage.length === 0) {
        selectedImage = props.product[0].media.filter(
          (item) => item.type === "Image"
        );
        setImage(selectedImage);
        return;
      }

      setImage(selectedImage);
    }
  }, [props.product]);

  const handleClick = (product, type) => {
    if (type === "quickshop") {
      window.open(product[0].url, "_blank", "noopener,noreferrer");
    } else if (type === "refresh") {
      let data = {
        text: "Show me alternatives for the " + product[0].name + ".", //as per Peter's comment
        query: "Show me alternatives for the product with ID " + product[0].id + ".",
        type: type,
      };
      props.onValueChange(data);
    }
  };

  useEffect(() => {
    setShowConclusionLoader(true);
    setTimeout(() => {
      setShowConclusionLoader(false);
    }, 2000);
  }, [isProductLoading]);

  return (
    <div className="singleproduct specificProduct">
      <div className="container container-fluid main-section">
        <div className="row productdetails-div">
          <div className="col-12 col-lg-8 mb-4 ps-0">
            <h4 className="intro-text">
              <Typewriter
                convoId={props.convoId}
                type="responseText"
                text={props.text}
                delay={props.delay}
                isAnswerComplete={props.isAnswerComplete}
                setLoading={setIsOverviewTextLoading}
                effects={props.effects}
              />
            </h4>
          </div>
          {((props.index !== conList.length - 1 &&
            props.text !== "" &&
            props.product &&
            props.product.length > 0) ||
            ((isOverviewTextLoading || props.text === "") &&
              props.product &&
              props.product.length > 0)) && (
              <div>
                <div className="row">
                  {video && video.url !== "" ? (
                    <div className=" col-12 col-md-12 col-lg-5 d-flex justify-content-center align-items-start px-0 img-fluid player-container pdp_video_container">
                      <ReactPlayer
                        className="main-img"
                        url={video.url}
                        playing={isPlaying}
                        loop
                        width="100%"
                        height="auto"
                        playsinline
                      />
                      {/* {showPlayIcon && !isiOS &&  */}
                      {showPlayIcon && (
                        <div className="col-12 sp-play-pause-overlay">
                          {isPlaying ? (
                            <img
                              src="./static/assets/pause.svg"
                              className="play-pause"
                              alt="pause"
                              onClick={handlePlayPause}
                            />
                          ) : (
                            <img
                              src="./static/assets/play.svg"
                              className="play-pause"
                              alt="play"
                              onClick={handlePlayPause}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="col-12 col-md-12 col-lg-5 d-flex  justify-content-center align-items-start px-0">
                      {primaryImage ? (
                        <img
                          src={
                            primaryImage.url !== ""
                              ? primaryImage.url.split("?fmt=webp-alpha")[0] +
                              "?$rl_4x5_pdp$&"
                              : "./static/assets/no-image.png"
                          }
                          className={`img-fluid ${primaryImage.url.split("?fmt=webp-alpha")[0] +
                              "?$rl_4x5_pdp$&"
                              ? "main-img"
                              : ""
                            }`}
                          alt="no img"
                        />
                      ) : (
                        <img
                          src="./static/assets/no-image.png"
                          className="img-fluid d-none d-lg-flex"
                          alt="no img"
                        />
                      )}
                    </div>
                  )}
                  <div className="col-12 col-md-12 col-lg-7 px-0 pe-md-3 pe-lg-0 d-flex flex-column">
                    <div className="order-2 ms-lg-5 ps-lg-3 pe-lg-4 me-lg-4 left-margin">
                      <h3 className="product-heading">
                        <Typewriter
                          text={getTitle(
                            props.product.length > 0 && props.product[0]
                          )}
                          delay={props.delay}
                          isAnswerComplete={props.isAnswerComplete}
                          setLoading={setIsTitleLoading}
                          effects={props.effects}
                          convoId={props.convoId}
                          type="productDetail"
                          productIndex={0}
                          propertyKey={"name"}
                        />
                      </h3>
                      <div className="divider horizontal_dash"></div>
                      {(getTitle(props.product.length > 0 && props.product[0]) ===
                        "" ||
                        isTitleLoading) &&
                        props.product.length > 0 &&
                        props.product[0]?.description && (
                          <h5
                            onClick={() => {
                              setShowTruncate(!showTruncate);
                            }}
                            className={`sub-text text1 
                          ${showTruncate ? "show_truncate" : ""}
                          ${props.product[0].description.length > 156 &&
                              "pdp_lg_cursor"
                              }
                          ${props.product[0].description.length > 197 &&
                              "pdp_xl_cursor"
                              }
                          ${props.product[0].description.length > 228 &&
                              "pdp_xxl_cursor"
                              }
                          `}
                          >
                            <Typewriter
                              text={props.product[0].description}
                              delay={props.delay}
                              isAnswerComplete={props.isAnswerComplete}
                              setLoading={setIsProductLoading}
                              effects={props.effects}
                              convoId={props.convoId}
                              type="productDetail"
                              productIndex={0}
                              propertyKey={"description"}
                            />
                          </h5>
                        )}
                      {((props.index !== conList.length - 1 &&
                        props.product &&
                        props.product.length > 0) ||
                        (isProductLoading &&
                          props.product &&
                          props.product.length > 0)) && (
                          <div className="mt-3">
                            <div className="d-flex">
                              <button
                                name="similarStylesButton"
                                className={`utility-btn ${!loading && "utility-btn-hover"
                                  }`}
                                disabled={loading}
                                onClick={() => {
                                  handleClick(props.product, "refresh");
                                }}
                              >
                                {" "}
                                <img
                                  className="utility_icons styles_icon"
                                  src="/static/assets/similarstyle_icon.svg"
                                  alt="similarstyle_icon"
                                />{" "}
                                <span className="d-none d-lg-inline">
                                  Similar Styles
                                </span>{" "}
                              </button>
                              <button
                                name="shopButton"
                                className={`utility-btn ${!(location.pathname === '/chat' && loading) && 'utility-btn-hover'}`} disabled={location.pathname === '/chat' && loading}
                                onClick={() => {
                                  handleClick(props.product, "quickshop");
                                }}
                              >
                                {" "}
                                <img
                                  className="utility_icons shop_icon"
                                  src="/static/assets/shop_icon.svg"
                                  alt="shop_icon"
                                />{" "}
                                <span className="d-none d-lg-inline"> Shop</span>
                              </button>
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="row pic-div order-1 col-md-12 ms-lg-0 pe-1">
                      {video && video.url !== "" ? (
                        <div className="col-7 col-sm- col-md-5 ps-3 pe-0 ps-md-3 sub-img  video-sm player-container scroll-snap">
                          <ReactPlayer
                            className="sub-img"
                            url={video.url}
                            playing={isPlaying}
                            loop
                            width="100%"
                            height="auto"
                            playsinline
                          />
                          {/* {showPlayIcon && !isiOS &&  */}
                          {showPlayIcon && (
                            <div className="col-12 sp-play-pause-overlay">
                              {isPlaying ? (
                                <img
                                  src="./static/assets/pause.svg"
                                  className="play-pause"
                                  alt="pause"
                                  onClick={handlePlayPause}
                                />
                              ) : (
                                <img
                                  src="./static/assets/play.svg"
                                  className="play-pause"
                                  alt="play"
                                  onClick={handlePlayPause}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="col-7 col-sm-4 col-md-5 ps-3 pe-0 px-md-2 display-img scroll-snap">
                          {primaryImage ? (
                            <img
                              src={
                                primaryImage.url !== ""
                                  ? primaryImage.url.split("?fmt=webp-alpha")[0] +
                                  "?$rl_4x5_pdp$&"
                                  : "./static/assets/no-image.png"
                              }
                              className="img-fluid display-img px-0"
                              alt="no img"
                            />
                          ) : (
                            <img
                              src="./static/assets/no-image.png"
                              className="img-fluid sub-img "
                              height="290px"
                              width="230px"
                              alt="no img"
                            />
                          )}
                        </div>
                      )}
                      {image?.slice(0, 3).map((item, i) => {
                        return (
                          <div
                          key={item.url || `image-${i}`}
                            className={`col-7 col-sm-7 col-md-5 col-lg-4 d-flex flex-column flex-column justify-content-start justify-content-md-center justify-content-lg-start px-0 sp-img ${i === image?.slice(0, 3)?.length - 1 && "grid-me-20"
                              } scroll-snap`}
                          >
                            <img
                              src={
                                item.url
                                  ? item.url.split("?fmt=webp-alpha")[0] +
                                  "?$rl_4x5_pdp$&"
                                  : "./static/assets/no-image.png"
                              }
                              width="100%"
                              alt="no img"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div className="px-0">
            {((props.index !== conList.length - 1 &&
              props.conclusion &&
              props.conclusion !== "") ||
              (((props.product.length === 0 && isOverviewTextLoading) ||
                isProductLoading) &&
                props.conclusion)) && (
                <section className="branding-conclusion">

                  {(showConclusionLoader && loading) ?
                    (<div style={{ margin: "10px 20px" }} className="cat-loader" />) :
                    <>
                      <Divider borderTop="border-1" borderBottom="border-2" />
                      <div className="mt col-lg-9" >
                        <Typewriter
                          convoId={props.convoId}
                          type="responseConclusion"
                          text={props.conclusion}
                          delay={props.delay}
                          isAnswerComplete={props.isAnswerComplete}
                          setLoading={setIsConclusionLoading}
                          effects={props.effects}
                        />
                      </div>
                    </>
                  }
                </section>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

SpecificProductDetails.propTypes = SpecificProductDetailsProps;

export default SpecificProductDetails;
