/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from "react";
import { LikeAndDislikeProps } from "../Services/CommonServices/propTypes";
import FeedbackModal from "./FeedbackModal";
import { useSelector, useDispatch } from "react-redux";
import { likeordislike } from "../Services/ApiServices/chatServices";
import { likeApiError } from "../Services/Redux/Reducers/feedBackSlice";

function LikeAndDislike(props) {
  const conList = useSelector((state) => state.chat.conversationList);
  const responseId = props.responseId;
  const [isThumsUp, setIsThumsUp] = useState(false);
  const [isThumsDown, setIsThumsDown] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isWarningClose, setIsWarningClose] = useState(true);
  const dispatch = useDispatch();
  const islikeApiError = useSelector((state) => state.feedBack.likeApiError);
  const stateShowLike = useSelector((state) => state.feedBack.showLikeDisLikeState);

  const onThumsUpHandle = (status) => {
    setIsThumsUp(!isThumsUp);
    setIsThumsDown(false);
    if (!isThumsUp) {
      updateResponseStatus(status);
    } else {
      updateResponseStatus("");
    }
  };

  const onThumsDownHandle = (status) => {
    setIsThumsDown(!isThumsDown);
    setIsThumsUp(false);
    setIsOpenModal(true);
    if (!isThumsDown) {
      updateResponseStatus(status);
    } else {
      updateResponseStatus("");
    }
  };

  const handleClose = useCallback(() => {
    setIsOpenModal(false);
  });

  // onclick like/unlike
  const updateResponseStatus = (status) => {
    let responseStatus = {
      responseId: responseId,
      status: status,
    };
    likeordislike(
      responseStatus,
      (response) => {
        dispatch(likeApiError(true));
      },
      (err) => {
        setIsThumsUp(false);
        setIsThumsDown(false);
        dispatch(likeApiError("An error occurred. Please try again"));
        // handleStopResponse();
      }
    );
  };

  return (
    <div className="col-11">
      {stateShowLike && (
        <>
          <div className="wrapper d-flex">
            {conList?.length > 1 && (
              <div className="wrapper_likeAndDislike_msg">
                <div className="wrapper_likeAndDislike">
                  <div>
                    <div className="d-flex justify-content-around align-items-start wrapper_box">
                      <button name="thumbsUp" className="feedback p-0">
                        <img
                          className="point"
                          src={
                            !isThumsUp
                              ? "./static/assets/DesktopThumbsUp.svg"
                              : "./static/assets/likeSolid.svg"
                          }
                          alt="img"
                          onClick={() =>
                            onThumsUpHandle(!isThumsUp ? "like" : "")
                          }
                        />
                      </button>
                      {/* <img
                        src="./static/assets/verticalBar.png"
                        height="19px"
                        width="1px"
                        style={{
                          border: "1px",
                          color: "#C6CBCC",
                          margin: "0px 10px",
                        }}
                        alt="img"
                      /> */}
                      <button name="thumbsDown" className="feedback p-0">
                        <img
                          className="point"
                          src={
                            !isThumsDown
                              ? "./static/assets/DesktopThumbsDown.svg"
                              : "./static/assets/dislikeSolid.svg"
                          }
                          alt="img"
                          onClick={(e) =>
                            onThumsDownHandle(!isThumsDown ? "dislike" : "")
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
                {conList?.length === 2 && !(isThumsUp || isThumsDown) && (
                      <div className="d-flex">
                        <span className="wrapper_spanMsg ms-3">
                          Help us improve our responses
                        </span>
                      </div>
                    )}
              </div>
            )}
            {(isThumsUp || isThumsDown) && islikeApiError === true ? (
              <div className="d-flex align-items-center justify-content-start">
                {/* <input value="Thanks for providing feedback" type="text"   disabled  className='wrapper_greetingTextBox_inputBox'/> */}
                <span className="fb_text">
                  Thank you for your feedback.
                </span>
              </div>
            ) : (
              <div className="text-danger">{islikeApiError}</div>
            )}
            {isWarningClose && props.sessionChange && (
              <div className="wrapper_greetingTextBox">
                <span className="wrapper_greetingTextBox_inputBox">
                  AI generated advice may be incorrect. Please check responses
                  for accuracy.
                </span>
                <button
                  name="closeButton"
                  className="cross_btn"
                  style={{ padding: "10px 16px" }}
                >
                  {" "}
                  <img
                    src="/static/assets/close.svg"
                    height="10px"
                    width="10px"
                    alt="arrow-img"
                    onClick={() => setIsWarningClose(false)}
                  />
                </button>
              </div>
            )}
          </div>

          {isThumsDown && (
            <FeedbackModal
              responseId={responseId}
              showModel={isOpenModal}
              handleClose={handleClose}
            />
          )}
        </>
      )}
    </div>
  );
}

LikeAndDislike.propTypes = LikeAndDislikeProps;

export default LikeAndDislike;
