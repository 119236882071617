import PropTypes from "prop-types";

export const SingleCategoryListProps = {
  products: PropTypes.array,
  isAnswerComplete: PropTypes.bool,
  text: PropTypes.string,
  conclusion: PropTypes.string,
  outfitUrl: PropTypes.string,
  branding: PropTypes.any,
  onValueChange: PropTypes.func,
  convoId: PropTypes.string,
  delay: PropTypes.number,
  isStopResponseTriggered: PropTypes.bool,
  setIsStopResponseTriggered: PropTypes.func,
  index: PropTypes.number,
  effects: PropTypes.bool,
  isStreaming: PropTypes.string,
};

export const ArchiveCarouselProps = {
  data: PropTypes.array,
  inputRef: PropTypes.object,
  isBackFromArchive: PropTypes.bool,
  setModalDeleteState: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
};

export const SpecificProductDetailsProps = {
  product: PropTypes.any,
  isAnswerComplete: PropTypes.bool,
  text: PropTypes.string,
  conclusion: PropTypes.string,
  onValueChange: PropTypes.func,
  convoId: PropTypes.string,
  delay: PropTypes.any,
  index: PropTypes.number,
  effects: PropTypes.bool,
};

export const MultiProductCardProps = {
  product: PropTypes.any,
  productIndex: PropTypes.number,
  cardLength: PropTypes.number,
  setProductIndex: PropTypes.func,
};

export const ProductCardProps = {
  product: PropTypes.any,
  onValueChange: PropTypes.func,
  convoId: PropTypes.string,
  isStopResponseTriggered: PropTypes.bool,
  setIsStopResponseTriggered: PropTypes.func,
  index: PropTypes.number,
  effects: PropTypes.bool,
};

export const MCatProductCardProps = {
  product: PropTypes.any,
  data: PropTypes.string,
  text: PropTypes.string,
  conclusion: PropTypes.string,
  onValueChange: PropTypes.func,
  convoId: PropTypes.string,
  onCardVisible: PropTypes.bool,
  index: PropTypes.number,
  effects: PropTypes.bool,
};

export const TextOnlyProps = {
  product: PropTypes.any,
  isAnswerComplete: PropTypes.bool,
  text: PropTypes.string,
  conclusion: PropTypes.string,
  onValueChange: PropTypes.func,
  convoId: PropTypes.string,
  delay: PropTypes.any,
  index: PropTypes.number,
  effects: PropTypes.bool,
  isStopResponseTriggered: PropTypes.bool,
  setIsStopResponseTriggered: PropTypes.func,
};

export const StreamingCardsProps = {
  response: PropTypes.any,
  isAnswerComplete: PropTypes.bool,
  onValueChange: PropTypes.func,
  isStreaming: PropTypes.bool,
  setIsStreaming: PropTypes.bool,
  effects: PropTypes.bool,
};

export const PromptsCarouselProps = {
  handleCaroselAnimate: PropTypes.func,
  isBackFromArchive: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
};

export const ConclusionProps = {
  setLoading: PropTypes.bool,
  isAnswerComplete: PropTypes.bool,
  text: PropTypes.string,
  delay: PropTypes.any,
  effects: PropTypes.bool,
};

export const BrandingCardProps = {
  branding: PropTypes.array,
  isAnswerComplete: PropTypes.bool,
  text: PropTypes.string,
  conclusion: PropTypes.string,
  convoId: PropTypes.string,
  delay: PropTypes.any,
  index: PropTypes.number,
  effects: PropTypes.bool,
};

export const MCatTopDividerProps = {
  onDividerVisible: PropTypes.bool,
  id: PropTypes.number,
};

export const MCatDividerProps = {
  onDividerVisible: PropTypes.bool,
  id: PropTypes.number,
};

export const MCatConclusionTextProps = {
  conclusionText: PropTypes.string,
  id: PropTypes.number,
  onAnimationEnd: PropTypes.string,
};

export const MCatProductDetailsProps = {
  branding: PropTypes.number,
  data: PropTypes.string,
  text: PropTypes.string,
  conclusion: PropTypes.string,
  convoId: PropTypes.string,
  delay: PropTypes.any,
  index: PropTypes.number,
  effects: PropTypes.bool,
};

export const BrandingConclusionProps = {
  text: PropTypes.string,
  id: PropTypes.number,
  delay: PropTypes.any,
  onAnimationEnd: PropTypes.string,
};

export const ChatHistoryProps = {
  onValueChange: PropTypes.func,
  isAnswerComplete: PropTypes.bool,
  shortName: PropTypes.string,
  isStopResponseTriggered: PropTypes.bool,
  setIsStopResponseTriggered: PropTypes.func,
};

export const ChatHistoryFeedBackProps = {
  responseId: PropTypes.string,
  showModel: PropTypes.bool,
  handleClose: PropTypes.func,
};

export const ChatOperationProps = {
  data: PropTypes.shape({
    sessionId: PropTypes.string,
    userId: PropTypes.string,
  }),
};

export const AnimatedImageProps = {
  url: PropTypes.any,
  delay: PropTypes.any,
  onImgShowFinish: PropTypes.number,
  imgClass: PropTypes.bool,
};

export const DislikeModuleProps = {
  isDislikeModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  updateRes: PropTypes.func,
};

export const DividerProps = {
  borderTop: PropTypes.string,
  borderBottom: PropTypes.string,
};

export const ErrorComponentProps = {
  error: PropTypes.shape({
    errorCode: PropTypes.string,
    errorMessage: PropTypes.string,
  }),
};

export const FeedbackModalProps = {
  responseId: PropTypes.string,
  showModel: PropTypes.bool,
  handleClose: PropTypes.func,
};

export const LikeAndDislikeProps = {
  responseId: PropTypes.string,
  sessionChange: PropTypes.bool,
};

export const MessageHistoryProps = {
  props: PropTypes.shape({
    isAnswerComplete: PropTypes.bool,
    isStopResponseTriggered: PropTypes.bool,
    setIsStopResponseTriggered: PropTypes.func,
    onValueChange: PropTypes.func,
  }),
};

export const BrandingTitleProps = {
  text: PropTypes.string,
  id: PropTypes.number,
  brandingImg: PropTypes.any,
  handleBrandingFinish: PropTypes.string,
  brandResponse: PropTypes.any,
  title: PropTypes.string,
};

export const MultiCatTemplateProps = {
  products: PropTypes.array,
  isAnswerComplete: PropTypes.bool,
  text: PropTypes.string,
  conclusion: PropTypes.string,
  outfitUrl: PropTypes.string,
  onValueChange: PropTypes.func,
  convoId: PropTypes.string,
  delay: PropTypes.number,
  isStopResponseTriggered: PropTypes.func,
  setIsStopResponseTriggered: PropTypes.func,
  index: PropTypes.number,
  effects: PropTypes.bool,
};

export const GridMultiProductsProps = {
  data: PropTypes.number,
  dataIndex: PropTypes.number,
};

export const ProductTitleProps = {
  onTitleComplete: PropTypes.bool,
  data: PropTypes.shape({
    responseText: PropTypes.string,
    responseTitle: PropTypes.string,
  }),
};

export const GridMutiProductListProps = {
  data: PropTypes.any,
  onValueChange: PropTypes.func,
  dataIndex: PropTypes.number,
  response: PropTypes.shape({
    responseConclusion: PropTypes.string,
  }),
};

export const GridDividerProps = {
  onDividerComplete: PropTypes.bool,
  id: PropTypes.number,
};

export const GridConclusionProps = {
  textConclusion: PropTypes.bool,
  onAnimationEnd: PropTypes.any,
  id: PropTypes.number,
};

export const GridCaroselCardProps = {
  textConclusion: PropTypes.bool,
  onAnimationEnd: PropTypes.any,
  id: PropTypes.number,
  data: PropTypes.any,
  onValueChange: PropTypes.func,
  dataIndex: PropTypes.number,
  onCaroselComplete: PropTypes.bool,
};

export const DeleteModalProps = {
  visibleModal: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
  modalTitle: PropTypes.string,
  modalBody: PropTypes.string.isRequired
};

export const TypeWriterProps = {
  text: PropTypes.string,
  delay: PropTypes.number,
  onComplete: PropTypes.func,
  index: PropTypes.number,
  effects: PropTypes.bool,
  isAnswerComplete: PropTypes.bool,
  isStreaming: PropTypes.bool,
  isStopResponseTriggered: PropTypes.bool,
  setIsStopResponseTriggered: PropTypes.func
};

export const OnboardCarouselProps = {
  handleGetStartedClick: PropTypes.func,
  handleSkip: PropTypes.func.isRequired
};

export const AbortControllerProviderProps = {
  children: PropTypes.node.isRequired
};

export const PrivateRouteProps = {
  children: PropTypes.node.isRequired
};

export const AbortControllerProps = {
  children: PropTypes.object,
};

export const ProductTypeWriterProps = {
  products: PropTypes.array,
  isAnswerComplete: PropTypes.bool,
  conclusion: PropTypes.string,
  convoId: PropTypes.string,
  index: PropTypes.number,
  isCategory: PropTypes.bool,
  onValueChange: PropTypes.func,
  isStreaming: PropTypes.bool,
  effects: PropTypes.bool,
  setLoading: PropTypes.func,
  isStopResponseTriggered: PropTypes.bool,
  setIsStopResponseTriggered: PropTypes.func.isRequired
};