import React from "react";
import { ErrorComponentProps } from "../Services/CommonServices/propTypes";

function ErrorComponent(props) {

    const getErrorMessage = () => {
        if (props.error.errorCode === "interaction_required") {
            return "User interaction is required to complete the login.";

        } else if (props.error.errorCode === "AADB2C90118") {
            return "User needs to reset their password.";
        } else if (props.error.errorCode === "login_progress") {
            return "Another login is already in progress.";
        } else {
            return props.error?.errorMessage || "An unknown error occurred during login"
        }
    };


    return (
        <div>
            <h2>An error occurred</h2>
            <p>{getErrorMessage()}</p>
        </div>
    )
}

ErrorComponent.propTypes = ErrorComponentProps;

export default ErrorComponent;